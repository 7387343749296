import { BASE_URL } from "./const";

const key = document.querySelector(".key") as any;
const keyhole = document.querySelector(".keyhole") as any;;
const ghost = document.querySelector(".ghost");

const heading = document.querySelector("h1");
const paragraph = document.querySelector("p");


const root = document.querySelector(":root");
const rootStyles = getComputedStyle(root);

const animationDuration = parseInt(rootStyles.getPropertyValue("--animation-duration")) * 1000;
let keyTimer = animationDuration * 9 / 8;

const keyBox = key.getBoundingClientRect();

const timeoutID = setTimeout(() => {
    key.parentElement.parentElement.style.cursor = "grab";

    key.style.animationPlayState = "running";
    keyhole.style.animationPlayState = "running";

    key.style.pointerEvents = "none";

    window.addEventListener("mousemove", updateKeyPosition);

    // when the cursor hovers on the keyhole, call a function to grant access and remove present listeners
    keyhole.addEventListener("mouseover", grantAccess);

    clearTimeout(timeoutID);
}, keyTimer);


// define the function which updates the position of the absolute-positioned key according to the mouse coordinates (and the keys own dimensions)
const updateKeyPosition = (e) => {
    let x = e.clientX;
    let y = e.clientY;
    key.style.left = x - keyBox.width / 1.5;
    key.style.top = y - keyBox.height / 2;
};

// define the function which notifies the user of the grant access
const grantAccess = () => {
    // restore the cursor
    key.parentElement.parentElement.style.cursor = "default";

    // change the text of the heading and paragraph elements
    heading.textContent = '🎉 yay 🎉';
    paragraph.textContent = 'Redirecting back to Admin Panel';

    // remove the svg elements for the key and keywhole from the flow of the document
    keyhole.style.display = "none";
    key.style.display = "none";

    // remove the event listeners, most notably the one on the window
    window.removeEventListener("mousemove", updateKeyPosition);
    keyhole.removeEventListener("mouseover", grantAccess);

    window.location.href = BASE_URL;
};
